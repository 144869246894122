.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-details{
  display:none;
}
.hover_file:hover .bg-details{
  background-color:rgba(0,0,0,0.4);
  display:block;
  position:absolute;
}
.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 3.9em;
  height: 3.9em;
  border: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #f5f1f1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}
.loadermap{
  position: absolute;
  top: calc(50% - 4em);
  left: calc(52% - 4em);
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid rgba(0, 0, 0, 0.2);
  border-left: 0.4em solid #f5f1f1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}
.loadermap2{
  position: absolute;
 
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid rgba(0, 0, 0, 0.2);
  border-left: 0.4em solid #f5f1f1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.overlay-shadow {
  box-shadow: 4px 1px 6px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 4px 1px 6px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 4px 1px 6px 0px rgba(0,0,0,0.75);
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 3.9em;
  height: 3.9em;
  border: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #f5f1f1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}
.loadermap{
  position: absolute;
  top: calc(50% - 4em);
  left: calc(52% - 4em);
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid rgba(0, 0, 0, 0.2);
  border-left: 0.4em solid #f5f1f1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.marker-position {
  bottom: 3.5px;
  left: 0;
  position: relative;
}
.color-white{
  color:#115293
}
.MuiTableCell-root {
  padding: 10px!important;

}
/* .MuiGrid-spacing-xs-2 > .MuiGrid-item {
 
  z-index: -2!important;
} */
