.top-nav {
    display: 'flex';
    width:100vw;
    /* visibility: hidden; */
    height:40px;
   background-color:rgb(255, 255, 255);
   position: fixed;
   left:0;
   top:0;
   padding:5px;
   box-shadow: -2px -1px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px -1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: -2px -1px 5px 0px rgba(0,0,0,0.75);
   
   /* z-index: 500; */
   
  }
  .upper-top-nav {
    display: 'flex';
    width:100vw;
    height:10px;
   background-color:#115293;
   position: fixed;
   left:0;
   top:0;
   padding:5px;
  }
   
   /* z-index: 500; */
   
  .parent{
      position: relative;
  }
  .map-parent{
      height:100vh ;
      width:100vw;
      position:absolute;
    
  }
  .dash-slider{
    width: 50%;
    height: 100vh;
    background-color: rgb(53, 53, 53);
    /* background-color: #222; */
    opacity: 0.90;
    /* background-color: rgb(252, 252, 252); */
    position: absolute;
    left: -50%;
    transition: 2s;
  }
  .dash-slider-button{
    /* width: 50%;
    height: 100vh; */
    /* background-color: rgb(53, 53, 53); */
    /* background-color: #222; */
    /* opacity: 0.90; */
    /* background-color: rgb(252, 252, 252); */
    position: absolute;
    left:-10%;
    top:60px;
    transition: 2s;
  }
  .wrapper .dash-slider{
    transition: 1s;
    left: 50%;
}
.card-color{
    padding:10px;
    background-color:rgba(30, 32, 38, 0.498) ;
    height: 100px;
}
.card-color-no-h{
    padding:10px;
    background-color: #222;
    opacity: 0.90;
    color: #fff;
    outline-color: #000;
 
    
}
.card-color-absolute{
    padding:10px;
    background-color:rgba(14, 24, 54, 0.498) ;
    height: 100px;
}
.card-color-3{
    padding:10px;
    background-color:rgba(30, 32, 38, 0.498) ;
}
.font-color-white{
    color:white
}
.bg-slider{
    position: absolute;
    width: 50%;
    height: 100vh;
    background-color: rgb(94, 102, 115);
}
.center-body{
    display: flex;
    align-items: center;
    justify-content: center;
}
.font-size-running{
    font-size: 16;
}
.font-size-running-2{
    font-size: 10!important;
}
.card-color-size{
    padding:10px;
    background-color:rgba(30, 32, 38, 0.498) ;
    height: 120px;
}
.card-color-size-two{
    padding:10px;
    background-color:rgba(30, 32, 38, 0.498) ;
    height: 210px;
}
.fieldman-list{
    width: 38%;
    height: 100vh;
    background-color: rgb(53, 53, 53);
    opacity: 0.90;
    position: absolute;
    left: -38%;
    transition: 2s;
}
.line-graph{
    
    padding:    10;
    width: 45%;
    height: 100vh;
    opacity: .91;
    background-color: rgb(53, 53, 53);
    /* background-color: rgb(252, 252, 252); */
    position: absolute;
    left: -50%;
    transition: 2s;
}

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.font-size-fieldman{
    font-size: 10;
}
.fieldmanDeatils-slider-left{
    width: 35%;
    height: 100vh;
    background-color: rgb(53, 53, 53);
    opacity: 0.90;
    position: absolute;
    left: -50%;
    transition: 2s;
    
}

.fieldmanHistory-slider-left{
width: 100%;
height: 100vh;
background-color: rgba(0,0,0,0.6);
position: absolute;
left: -100%;
transition: 2s;

}
  .card-color-delivery-type-fieldman{
    padding:10px;
    /* background-color:rgb(12, 12, 15); */
    /* color: #fff; */
    outline-color: #000;
    opacity: .86;
    font-weight: bold!important;
}
.fieldmanDeatils-slider-right{
    width: 32%;
    /* background-color: rgb(252, 252, 252); */
    position: absolute;
    /* top: -50%; */
    right:1%;
    transition: 2s;
  }
  .display_close{
      display: none;
  }
  .fieldman-icon-transition{
    background-color:'red';
    width: 40;
    height: 40;
    border-radius:40/2
  }
  .fieldmanDeatils-open{
    width: 30%;
    height: 100vh;
    background-color: rgb(53, 53, 53);
    opacity: 0.90;
    position: absolute;
    left: -50%;
    transition: 2s;
    
  }
  .nav-icon{
    /* background-color: #115293!important; */
    /* margin-right: 5px!important;
    width: 40px!important;
    height: 40px!important; */
  }
  .nav-icon2{
    /* background-color: #115293!important; */
    margin-right: 5px!important;
    width: 40px!important;
    height: 40px!important;
  }
  .nav-icon-span {
    display: none;  
    font-size: small;
  }
  .nav-icon2:hover{
    /* background-color: #fff !important; */
    border-radius:20px;
    color: #fff !important;
    width: 150px!important;
    transition: width 0.4s;
    content: attr(data-employeename);
  }
  .nav-icon2:hover .nav-icon-span {
    display: inline;
  }
  .navigation-list-settings{
    display:flex;
    width:100%;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.font-size-findngs{
    font-size:13.5 !important;
}
.card-line{
    margin-right: 25px !important;
    margin-left: 25px !important;
    background-color:#222!important;
}
.card-fieldman{
    margin-bottom: 10px;
    margin-right: 15px;
    margin-left: 25px;
    background-color:#222!important;
    color:#fff !important;
}
.card-meter-details{
    width: 100%;
    padding: 10px;
    color: #fff!important;;
    background-color: rgb(53, 53, 53) !important;
}
.card-color-data{
    background-color:#222!important;
}
.breakdown{
    background-color:#333232!important;
}
